import { useRouter } from '@galileo/composables/useRouter'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

import { storeToRefs } from 'pinia'
import { PaymentMethod } from '@galileo/models/Transaction/app.js'

import {
  useSendMoneyStore,
  useAnalyticsStore,
  usePaymentsStore,
  useAuthStore,
  useCardAccountStore,
} from '@galileo/stores'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'

export default function (useStorePaymentMethod = true) {
  const analyticsStore = useAnalyticsStore()
  const router = useRouter()

  const paymentsStore = usePaymentsStore()
  const authStore = useAuthStore()
  const sendMoneyStore = useSendMoneyStore()
  const { isCorporateAccount } = storeToRefs(authStore)
  const cardAccount = useCardAccountStore()

  // Validate append
  // If called directly from click, $event is passed in some instances
  const validateAppend = (append) => {
    if (typeof append !== 'boolean') {
      return false
    }
    return append
  }

  const addCreditCard = (append = false) => {
    const cardType = PAYMENT_METHODS.CREDIT_CARD
    paymentsStore.addCard(cardType)
    router.push({
      path: 'add-credit-card',
      append: validateAppend(append),
    })
  }

  const addDebitCard = (append = false) => {
    const cardType = PAYMENT_METHODS.DEBIT_CARD
    paymentsStore.addCard(cardType)
    router.push({
      path: 'add-debit-card',
      append: validateAppend(append),
    })
  }

  const addBankAccount = (append = false) => {
    router.push({ path: 'add-new', append: validateAppend(append) })
  }

  const addPaymentMethod = () => {
    //we default the paymentMethod to Direct Debit
    let paymentMethodType = PAYMENT_METHODS.DIRECT_DEBIT

    const userProfile = authStore.userProfile

    if (!useStorePaymentMethod && (userProfile.country === 'NZ' || userProfile.country === 'AU')) {
      router.push({ path: 'contact-us', append: true })
      return
    }

    if (useStorePaymentMethod) {
      if (paymentMethodType === PaymentMethod.DirectDebit.enumName && isCorporateAccount.value) {
        if (userProfile.country === 'NZ' || userProfile.country === 'AU') {
          router.push({ path: 'contact-us', append: true })
          return
        }
      }

      paymentMethodType = sendMoneyStore.form.paymentMethod

      if (paymentMethodType === PAYMENT_METHODS.CREDIT_CARD) {
        addCreditCard(true)
      } else if (paymentMethodType === PAYMENT_METHODS.DEBIT_CARD) {
        addDebitCard(true)
      }
    }

    if (cardAccount.selectedCardForUpdate) {
      analyticsStore.track({
        event: SEGMENT_EVENTS.UPDATE_PAYMENT_METHOD_STARTED,
        traits: {
          paymentType: paymentMethodType,
          paymentProvider: authStore.userProfile.customer.cardPaymentGateway,
          location: useStorePaymentMethod
            ? SEGMENT_LOCATIONS.SEND_MONEY
            : SEGMENT_LOCATIONS.ACCOUNT,
        },
      })
    } else {
      analyticsStore.track({
        event: SEGMENT_EVENTS.NEW_PAYMENT_METHOD_STARTED,
        traits: {
          paymentType: paymentMethodType,
          paymentProvider: authStore.userProfile.customer.cardPaymentGateway,
          location: useStorePaymentMethod
            ? SEGMENT_LOCATIONS.SEND_MONEY
            : SEGMENT_LOCATIONS.ACCOUNT,
        },
      })
    }

    //exit the flow before using the router if the flag is set to true
    //useStorePaymentMethod  should be used inside SMF
    if (useStorePaymentMethod && paymentMethodType !== PAYMENT_METHODS.DIRECT_DEBIT) {
      return
    }
    router.push({ path: 'add-new', append: true })
  }

  return {
    addCreditCard,
    addDebitCard,
    addBankAccount,
    addPaymentMethod,
  }
}
