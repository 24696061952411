<template>
  <InformationAppModal :value="model" :icon="ICON_TYPE.HOURGLASS">
    <template #default>
      <h1 class="title">{{ $t('PendingApprovalModal.PendingApprovalHeading').value }}</h1>
      <p>
        {{ $t('PendingApprovalModal.PendingApprovalText').value }}
      </p>
    </template>
    <template #footer>
      <AppButton @click="backToHome">
        {{ primaryButtonText }}
      </AppButton>
      <AppButton theme="text" @click="onLearnMore">
        {{ $t('PendingApprovalModal.LearnMoreButton').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { defineComponent } from '@vue/runtime-dom'
import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton, useVModel } from '@oen.web.vue2/ui'

import { useRouter } from '@galileo/composables/useRouter'

import {
  useSendMoneyStore,
  useAppStore,
  useI18nStore,
  useQuickTransferStore,
} from '@galileo/stores'

export default defineComponent({
  name: 'PendingApprovalModal',
  components: {
    AppButton,
    InformationAppModal,
  },
  emits: ['update:modelValue'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const router = useRouter()
    const { model } = useVModel(props, emit)
    const appStore = useAppStore()
    const quickTransferStore = useQuickTransferStore()
    const sendMoneyStore = useSendMoneyStore()

    const primaryButtonText = computed(() =>
      quickTransferStore.isCurrentTransactionQuickTransfer
        ? $t('QuickTransfer.PendingApprovalOk').value
        : $t('PendingApprovalModal.BackToHomeButton').value
    )

    const backToHome = () => {
      if (quickTransferStore.isCurrentTransactionQuickTransfer) {
        router.replace({ name: 'QuickTransfer' })
      } else {
        router.replace({ name: 'Activity' })
      }
      model.value = false
    }

    // TODO
    const onLearnMore = () => {
      appStore.openHelpDesk({ loadingRef: ref(false), article: 'hc/articles/360019471957' })
    }

    return {
      primaryButtonText,
      $t,
      model,
      backToHome,
      onLearnMore,
      ICON_TYPE,
    }
  },
})
</script>
