import { PaymentMethod } from '@galileo/models/Transaction/app'

export default function () {
  const getText = (paymentMethod) => {
    return PaymentMethod[paymentMethod].text
  }

  const getTextKey = (paymentMethod) => {
    return PaymentMethod[paymentMethod].textKey
  }

  return {
    getText,
    getTextKey,
  }
}
